import React from "react";

function LoadingSpinner() {
  return (
    <div class=" my-5">
      <div class="spinner-border text-info" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingSpinner;
