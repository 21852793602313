import React from "react";

import AdminHeader from "../Components/AdminHeader";
import Counter from "./Counter";

function Dashboard() {
  return (
    <div>
      <AdminHeader />
      <div class="mt-5 pt-5">
        <Counter />
      </div>
    </div>
  );
}

export default Dashboard;
