import React, { useState } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API;

function RegForm() {
  const [regDate, setRegDate] = useState(new Date());
  const [massege, setMassege] = useState("");
  const [loading, setLoading] = useState(false);

  const [CourseName, setCourseName] = useState("");
  const [applicanntType, setApplicanntType] = useState("");
  const [traningSession, setTraningSession] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [nidCard, setNidCard] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [whatsApp, setWhatsApp] = useState();
  const [homePhone, setHomePhone] = useState();
  const [homeContactPersonNumber, setHomeContactPersonNumber] = useState();
  const [presentAddress, setPresentAddress] = useState("");
  const [permanentsAddress, setPermanentsAddress] = useState("");

  console.log(CourseName);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const submitHandaler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !CourseName ||
      !applicanntType ||
      !traningSession ||
      !applicantName ||
      !fatherName ||
      !nidCard ||
      !dateOfBirth ||
      !mobileNumber ||
      !whatsApp ||
      !homePhone ||
      !homeContactPersonNumber ||
      !presentAddress ||
      !permanentsAddress
    ) {
      return setMassege("*** All Filds are required !!");
    }
    try {
      const response = await axios.post(`${baseURL}/api/students`, {
        CourseName,
        applicanntType,
        traningSession,
        applicantName,
        fatherName,
        nidCard,
        dateOfBirth,
        mobileNumber,
        whatsApp,
        homePhone,
        homeContactPersonNumber,
        presentAddress,
        permanentsAddress,
      });

      if (response.status === 200) {
        setMassege("Registration Successful");
      }
      reset();
    } catch (error) {
      setMassege(error.massege);
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setCourseName("");
    setApplicanntType("");
    setTraningSession("");
    setApplicantName("");
    setFatherName("");
    setNidCard("");
    setDateOfBirth("");
    setMobileNumber("");
    setWhatsApp("");
    setHomePhone("");
    setHomeContactPersonNumber("");
    setPresentAddress("");
    setPermanentsAddress("");
  };

  const handleNumericChange = (e, setState) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setState(value);
    }
  };

  return (
    <div className="mt-5 container reg-form">
      <p className="fw-bold my-3 h4">Registration Form (রেজিস্ট্রেশন ফরম)</p>

      <div className="row mt-5 pt-5">
        {/* Left Column */}
        <div className="col-sm-12 col-md-4 mb-3">
          <p className="fw-bold">Course Information (কোর্সের তথ্য)</p>
          <div className="mb-3 mt-5 ">
            <label htmlFor="regDate" className="form-label">
              Registration Date : (রেজিস্ট্রেশনের তারিখ)
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="regDate"
              value={formatDate(regDate)}
              disabled
            />
            <label htmlFor="courseName" className="form-label">
              Course Name : (কোর্সের নাম)
            </label>
            <select
              className="form-select form-select-sm mb-4"
              id="courseName"
              aria-label="Select course"
              value={CourseName}
              onChange={(e) => setCourseName(e.target.value)}
            >
              <option selected>কোর্সের নাম সিলেক্ট করুন</option>
              <option value="Basic(Level-1">Basic(Level- 1 & 2) )</option>
              <option value="Advance(Level-3)">Advance(Level-3)</option>
            </select>

            <label htmlFor="applicantType" className="form-label">
              Applicant Type : (আবেদনকারীর ধরন)
            </label>
            <select
              className="form-select form-select-sm mb-4"
              id="applicantType"
              aria-label="Select applicant type"
              value={applicanntType}
              onChange={(e) => setApplicanntType(e.target.value)}
            >
              <option selected>আবেদনকারীর ধরন সিলেক্ট করুন</option>
              <option value="Resident">Resident </option>
              <option value="Non-Residents">Non-Residents</option>
            </select>

            <label htmlFor="trainingSession" className="form-label">
              Training Session : (প্রশিক্ষণ সেশন)
            </label>
            <select
              className="form-select form-select-sm mb-4"
              id="trainingSession"
              aria-label="Select training session"
              value={traningSession}
              onChange={(e) => setTraningSession(e.target.value)}
            >
              <option selected>প্রশিক্ষণ সেশন সিলেক্ট করুন</option>
              <option value="Morning">Morning</option>
              <option value="Evening">Evening</option>
            </select>
          </div>
        </div>
        {/* Middle Column */}
        <div className="col-sm-12 col-md-4 mb-3">
          <p className="fw-bold">Personal Information : (ব্যক্তিগত তথ্য)</p>
          <div className="mb-3 mt-5">
            <label htmlFor="applicantName" className="form-label">
              Applicant Name : (আবেদনকারীর নাম)
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="applicantName"
              required
              placeholder="নাম : মোহাম্মদ আব্দুল্লাহ"
              value={applicantName}
              onChange={(e) => setApplicantName(e.target.value)}
            />

            <label htmlFor="fatherName" className="form-label">
              Father's Name : (পিতার নাম)
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="fatherName"
              required
              placeholder="নাম : মোহাম্মদ আমিন"
              value={fatherName}
              onChange={(e) => setFatherName(e.target.value)}
            />

            <label htmlFor="nidCard" className="form-label">
              NID Card No: (এন.আই.ডি কার্ড নম্বর)
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="nidCard"
              required
              placeholder="নম্বর : 098765432"
              value={nidCard}
              onChange={(e) => handleNumericChange(e, setNidCard)}
            />

            <label htmlFor="dob" className="form-label">
              Date of Birth : (জন্ম তারিখ)
            </label>
            <input
              type="date"
              className="form-control form-control-sm shadow-sm mb-4"
              required
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Present Address : (বর্তমান ঠিকানা)
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="ঠিকানা : ঢাকা, বাংলাদেশ"
                value={presentAddress}
                onChange={(e) => setPresentAddress(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        {/* Right Column */}
        <div className="col-sm-12 col-md-4 mb-3">
          <div className="mb-3 mt-5 pt-4">
            <label htmlFor="mobile" className="form-label">
              Mobile No: (মোবাইল নম্বর)
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="mobile"
              required
              placeholder="নম্বর : 01700000000"
              value={mobileNumber}
              onChange={(e) => handleNumericChange(e, setMobileNumber)}
            />

            <label htmlFor="whatsApp" className="form-label">
              WhatsApp No: (হোয়াটস অ্যাপ নম্বর)
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="whatsApp"
              required
              placeholder="নম্বর : 01700000000"
              value={whatsApp}
              onChange={(e) => handleNumericChange(e, setWhatsApp)}
            />

            <label htmlFor="homePhone" className="form-label">
              Home Phone No: (বাড়ির ফোন নম্বর)
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="homePhone"
              required
              placeholder="নম্বর : 01700000000"
              value={homePhone}
              onChange={(e) => handleNumericChange(e, setHomePhone)}
            />

            <label htmlFor="contactPerson" className="form-label">
              Contact Person: (বাড়ির যোগাযোগ ব্যক্তির ন: )
            </label>
            <input
              type="text"
              className="form-control form-control-sm shadow-sm mb-4"
              id="contactPerson"
              required
              placeholder="নম্বর : 01700000000"
              value={homeContactPersonNumber}
              onChange={(e) =>
                handleNumericChange(e, setHomeContactPersonNumber)
              }
            />
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Permanent Address : (স্থায়ী ঠিকানা)
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="ঠিকানা : ঢাকা, বাংলাদেশ"
                value={permanentsAddress}
                onChange={(e) => setPermanentsAddress(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <p className="fw-bold text-danger text-end mb-3">{massege}</p>
      <div className="d-flex justify-content-end">
        <button onClick={submitHandaler} className="btn btn-danger shadow mb-5">
          {loading ? "Loading...." : "Reg. Now"}
        </button>
      </div>
    </div>
  );
}

export default RegForm;
