import React from "react";

import HeroImage from "../Image/hero.HEIC";

function Hero() {
  return (
    <div className="mt-5 pt-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6 hero-image mb-5">
            <img
              className="img-fluid shadow"
              src={HeroImage}
              alt="opu Telecom"
            />{" "}
          </div>
          <div class="col-sm-12 col-md-6 d-flex align-items-center mt-3">
            <div>
              {" "}
              <p className="h2">
                মোবাইল সার্ভিসিং প্রশিক্ষন নিয়ে নিজেকে দক্ষ করে তুলুন, চাকরি
                আপনাকে খুঁজবে। আপনার চাকরি খুঁজতে হবেনা !!!
              </p>
              <p className="mt-5">
                কোর্স শুরু করে নিজেকে এক ধাপ এগিয়ে নিয়ে যান মোবাইল সার্ভিসিং এর
                জগতে।
              </p>
            </div>
          </div>
        </div>
        {/* details */}
        <div>
          <p>
            আমাদের ট্রেইনিং সেন্টারে এডমিশন নিলেই পাচ্ছেন ১০% ছাড় এবং ১০টি
            টুলস এর কম্বো প্যাক।
          </p>
          <p>
            কোর্স শুরু করে নিজেকে এক ধাপ এগিয়ে নিয়ে যান মোবাইল সার্ভিসিং এর
            জগতে। বিস্তারিত জানতে --- <br />
            <br />
            কল করুন :<span className="fw-bold"> 01814 120 076</span> <br />{" "}
            হোয়াটসঅ্যাপ করুন : <span className="fw-bold"> 01814 120 076</span>  
          </p>
          <p>
            অপু টেলিকম এন্ড যুব ট্রেইনিং ইন্সটিটিউট এর হট লাইন নাম্বার :
            <span className="fw-bold">  01814 120 076</span>
          </p>
        </div>

        {/* youtube */}
        <div className="my-5 rounded shadow">
          <div className="ratio rounded ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/aXAtj1Pem-8"
              title="YouTube video"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        {/* form submition */}
      </div>
    </div>
  );
}

export default Hero;
