import React, { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";

// Table Component
function Table({
  columns,
  data,
  keyField = "id",
  sortBy = "id",
  sortOrder = "desc",
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  // Filtered data based on search term
  const filteredData = data.filter((row) =>
    columns.some((column) =>
      String(row[column.accessor])
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  // Sorting logic (Descending order based on the sortBy column)
  const sortedData = filteredData.sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }
    return a[sortBy] < b[sortBy] ? 1 : -1;
  });

  // Pagination
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="rounded shadow p-3">
      <div className="d-flex justify-content-between mb-3">
        <input
          type="text"
          className="form-control form-control-sm shadow"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* CSV Download button is commented out */}
      </div>
      <table className="table table-sm table-striped table-hover table-css">
        <thead className="table-dark ">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.length > 0 ? (
            currentRows.map((row) => (
              <tr key={row[keyField]}>
                {columns.map((column, index) => (
                  <td key={index}>{row[column.accessor]}</td>
                ))}
              </tr>
            ))
          ) : (
            <div className="text-center my-5">
              <p>No Data Available</p>
            </div>
          )}
        </tbody>
      </table>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li
              className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <button className="page-link">Previous</button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                <button className="page-link">{index + 1}</button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <button className="page-link">Next</button>
            </li>
          </ul>
        </nav>
        <div>
          Page {currentPage} of {totalPages}
        </div>
      </div>
    </div>
  );
}

export default Table;
