import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Table from "../ChildComponents/Table";
import AdminHeader from "./AdminHeader";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LoadingSpinner from "../ChildComponents/LoadingSpinner";

const baseURL = process.env.REACT_APP_API;

function Students() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dataGet = () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${baseURL}/api/students`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setData(response.data.newStudentsList);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const redataGet = () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${baseURL}/api/students`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setData(response.data.newStudentsList);
        setLoading(false);
        toast.success("Updated Secessfully");
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    dataGet();
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  // Format the date to DD-MM-YYYY
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const columns = [
    {
      header: "Reg. Date",
      accessor: "regDate",
    },
    {
      header: "Students ID",
      accessor: "index",
    },
    { header: "Students Name", accessor: "applicantName" },
    {
      header: "Course Name",
      accessor: "CourseName",
    },
    { header: "Applicant Type", accessor: "applicanntType" },
    { header: "Training session", accessor: "traningSession" },
    { header: "Mobile Number", accessor: "mobileNumber" },
    {
      header: "Actions",
      accessor: "actionButton",
    },
  ];

  // Mapping over student data to include action buttons
  const studentAction = data.map((student, index) => ({
    ...student,
    index: index + 1,
    regDate: formatDate(student.createdAt),
    actionButton: (
      <>
        <button
          className="btn btn-light btn-sm  me-2 mb-2 border border-primary"
          onClick={() => navigate(`/admin/students/${student._id}`)}
        >
          <InfoIcon />
        </button>
        <button
          className="btn btn-danger btn-sm  me-2 mb-2 "
          onClick={() => hideHandaler(student._id)}
        >
          <DeleteForeverIcon />
        </button>
      </>
    ),
  }));

  const hideHandaler = async (id) => {
    console.log(id);

    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      const studentStatus = "cancel";
      const token = localStorage.getItem("token");
      await axios
        .patch(
          `${baseURL}/api/students/${id}`,
          { studentStatus },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          if (response.status === 201) {
            toast.success("Deleted successfully");
            setLoading(false);
            dataGet();
          } else {
            toast.warning("Try Again !!");
          }
        })
        .catch((error) => {
          toast.error(error.message);
          setLoading(false);
        });
      console.log("Delete Successful");
    } else {
      // If user clicks "Cancel"
      console.log("Delete canceled");
    }
  };

  return (
    <div>
      <AdminHeader />
      <div className="mt-5 container-fluid">
        <button
          className="btn btn-success btn-sm  ms-5 mb-5"
          onClick={redataGet}
        >
          Click For Update Data
        </button>
        <Table
          columns={columns}
          data={studentAction}
          keyField="_id"
          sortBy="createdAt"
          sortOrder="desc"
        />
      </div>
    </div>
  );
}

export default Students;
