import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminHeader from "./AdminHeader";

import axios from "axios";
const baseURL = process.env.REACT_APP_API;

function StudentsPage() {
  const { id } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStudentData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${baseURL}/api/students/${id}`, {
          headers: { Authorization: token },
        });
        setStudentData(response.data.data);
      } catch (err) {
        setError("Failed to fetch student data");
      } finally {
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [id]);

  const handleUpdate = async () => {
    try {
      const updatedData = {
        // Include the data you want to update
        CourseName: studentData.CourseName,
        applicanntType: studentData.applicanntType,
        traningSession: studentData.traningSession,
        applicantName: studentData.applicantName,
        fatherName: studentData.fatherName,
        nidCard: studentData.nidCard,
        dateOfBirth: studentData.dateOfBirth,
        mobileNumber: studentData.mobileNumber,
        whatsApp: studentData.whatsApp,
        homePhone: studentData.homePhone,
        homeContactPersonNumber: studentData.homeContactPersonNumber,
        presentAddress: studentData.presentAddress,
        permanentsAddress: studentData.permanentsAddress,
        studentStatus: studentData.studentStatus,
        paymentStatus: studentData.paymentStatus,
      };

      await axios.put(`/api/students/${id}`, updatedData); // Send update request to the server
      alert("Student data updated successfully!"); // Show success message
    } catch (err) {
      console.error(err);
      alert("Failed to update student data");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <AdminHeader />
      <div className="mt-5 container">
        <div className="row">
          <div className="col-sm-6">
            <div className="shadow m-2 p-3 rounded">
              <p className="h6 border-bottom border-danger mb-3">Course Info</p>
              <p>
                Course Name :{" "}
                <span className="fw-bold text-primary">
                  {studentData.CourseName}
                </span>
              </p>
              <p>
                Applicannt Type :{" "}
                <span className="fw-bold text-primary">
                  {studentData.applicanntType}
                </span>
              </p>
              <p>
                Traning Session :{" "}
                <span className="fw-bold text-primary">
                  {studentData.traningSession}
                </span>
              </p>
              <p>
                Student Status :{" "}
                <span className="fw-bold text-primary">
                  {studentData.studentStatus}
                </span>
              </p>
              <p>
                Payment Status :{" "}
                <span className="fw-bold text-primary">
                  {studentData.paymentStatus}
                </span>
              </p>
            </div>
            {/* <button className="mt-5 btn btn-success" onClick={handleUpdate}>
              <EditIcon />
            </button> */}
          </div>
          <div className="col-sm-6">
            <div className="shadow m-2 p-3 rounded">
              <p className="h6 border-bottom border-danger mb-3">
                Students Info
              </p>
              <p>
                Applicant Name :{" "}
                <span className="fw-bold text-primary">
                  {studentData.applicantName}
                </span>
              </p>
              <p>
                Father Name :{" "}
                <span className="fw-bold text-primary">
                  {studentData.fatherName}
                </span>
              </p>
              <p>
                NID Card # :{" "}
                <span className="fw-bold text-primary">
                  {studentData.nidCard}
                </span>
              </p>
              <p>
                Date Of Birth :{" "}
                <span className="fw-bold text-primary">
                  {new Date(studentData.dateOfBirth).toLocaleDateString()}
                </span>
              </p>
              <p>
                Mobile Number :{" "}
                <span className="fw-bold text-primary">
                  {studentData.mobileNumber}
                </span>
              </p>
              <p>
                What's App Number :{" "}
                <span className="fw-bold text-primary">
                  {studentData.whatsApp}
                </span>
              </p>
              <p>
                Home Contact Person Number :{" "}
                <span className="fw-bold text-primary">
                  {studentData.homeContactPersonNumber}
                </span>
              </p>
              <p>
                Home Phone :{" "}
                <span className="fw-bold text-primary">
                  {studentData.homePhone}
                </span>
              </p>
              <p>
                Present Address :{" "}
                <span className="fw-bold text-primary">
                  {studentData.presentAddress}
                </span>
              </p>
              <p>
                Permanents Address :{" "}
                <span className="fw-bold text-primary">
                  {studentData.permanentsAddress}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsPage;
