import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="bg-dark pt-1 text-white text-center fixed-bottom">
      <p>
        &copy; 2024{" "}
        <span onClick={() => navigate("/login")} className="fw-bold coursor">
          ensandt.{" "}
        </span>{" "}
        All Rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
