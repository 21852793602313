import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// icon
import EmailIcon from "@mui/icons-material/Email";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// components
import ContactHeader from "../ChildComponents/ContactHeader";

// image
import logoImage from "../Image/logo.png";

function Header() {
  const [mobileView, setMobileView] = useState(false);

  const mobileNav = () => {
    if (mobileView === false) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  return (
    <div className="shadow">
      <div class="top-bg-brand"></div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xl-4 logo mb-3 header-mobile justify-content-between">
            <Link className="d-flex" to="/">
              <img
                className="img-fluid"
                src={logoImage}
                alt="Opu Telecom & Training Center"
              />
              <p className="ms-2 mt-2">
                অপু টেলিকম এন্ড <br /> যুব ট্রেইনিং ইন্সটিটিউট
              </p>
            </Link>
            <MenuIcon onClick={mobileNav} className="mobile-nav-icon " />
          </div>
          <div class="col-sm-12 col-md-12 col-xl-8 d-flex justify-content-end header-mobile">
            <ContactHeader />
          </div>
        </div>
      </div>
      {/* mobile nav */}
      {mobileView ? (
        <div className="mobile-nav-view">
          <div className=" pt-5">
            <button
              onClick={mobileNav}
              className="btn btn-danger mt-4 ms-5 mb-5"
            >
              <CloseIcon />
            </button>
            <div>
              {/* email */}
              <Link to="/">
                <div className="d-flex mx-2 font-mobile">
                  <EmailIcon className="me-2" />
                  <p className="mt-1 text-nowrap">oputelecom76@gmail.com</p>
                </div>
              </Link>
              {/* helpline */}
              <Link to="/">
                <div className="d-flex mx-2 ">
                  <SupportAgentIcon className="me-2 " />
                  <p className="mt-1 text-nowrap">+880 1814 120 076</p>
                </div>
              </Link>
              {/* email */}
              <Link to="/">
                <div className="d-flex mx-2 ">
                  <WhatsAppIcon className="me-2" />
                  <p className="mt-1 text-nowrap">+880 1814 120 076</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Header;
