import React from "react";
import Header from "../Components/Header";
import Hero from "./Hero";
import RegForm from "../ChildComponents/RegForm";
import Counter from "./Counter";

function LandingPage() {
  return (
    <div>
      <Header />
      <Hero />
      <Counter />
      <RegForm />
    </div>
  );
}

export default LandingPage;
