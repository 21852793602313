import React from "react";
import { Link } from "react-router-dom";

// icon
import EmailIcon from "@mui/icons-material/Email";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function ContactHeader() {
  return (
    <div className="d-flex connect align-items-center mobile-nav-contact">
      {/* email */}
      <Link className="mobile-nav-contact" to="/">
        <div className="d-flex mx-2 ">
          <EmailIcon className="me-2" />
          <p className="mt-1 text-nowrap">oputelecom76@gmail.com</p>
        </div>
      </Link>
      {/* helpline */}
      <Link className="mobile-nav-contact" to="/">
        <div className="d-flex mx-2 ">
          <SupportAgentIcon className="me-2 " />
          <p className="mt-1 text-nowrap">+880 1814 120 076</p>
        </div>
      </Link>
      {/* email */}
      <Link className="mobile-nav-contact" to="/">
        <div className="d-flex mx-2 ">
          <WhatsAppIcon className="me-2" />
          <p className="mt-1 text-nowrap">+880 1814 120 076</p>
        </div>
      </Link>
    </div>
  );
}

export default ContactHeader;
